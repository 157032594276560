<svg x="0px" y="0px" viewBox="0 0 512 512" width="6em" height="6em">
  <g>
    <g>
      <polygon
        points="289.391,222.609 289.391,0 222.609,0 222.609,222.609 0,222.609 0,289.391 222.609,289.391 222.609,512 
			289.391,512 289.391,289.391 512,289.391 512,222.609"
      />
    </g>
  </g>
</svg>
